import React from "react"

import Seo from "../components/seo"
import {
    button,
    buttonContainer,
    form,
    icon,
    label,
    mail,
    mailContainer,
    message,
    messageContainer,
    name,
    nameContainer,
    sender,
    twitterMessage
} from "./contact.module.scss"
import { faPaperPlane, faEnvelope, faSignature, faInbox } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


const ContactPage = ({ data }) => (
    <>
        <Seo title="Contact"/>
        <h1>Contact me</h1>
        <p>
            Feel free to reach out with any message, questions or proposal you might have! <br/>
        </p>
        <form name="contact" method="POST" data-netlify="true"
              netlify-honeypot="bot-field"
              className={form}
              action="/contact-success">
            <input type="hidden" name="form-name" value="contact"/>
            <input type="hidden" name="bot-field"/>
            <div className={sender}>
                <div className={nameContainer}>
                    <label>
                        <FontAwesomeIcon icon={faSignature} className={"fa-fw " + icon}/>
                        <strong className={label}>Your Name:</strong>

                        <input type="text"
                               name="name"
                               placeholder="Name"
                               className={name}/>
                    </label>
                </div>
                <div className={mailContainer}>
                    <label>
                        <FontAwesomeIcon icon={faInbox} className={"fa-fw " + icon}/>
                        <strong className={label}>Your Email:</strong>
                        <input type="email"
                               name="email"
                               placeholder="Mail"
                               className={mail}/>
                    </label>
                </div>

            </div>
            <p className={messageContainer}>
                <label>
                    <FontAwesomeIcon icon={faEnvelope} className={"fa-fw " + icon}/>
                    <strong className={label}>Message:</strong>
                    <textarea name="message"
                              placeholder="Your message"
                              className={message}/>
                </label>
            </p>
            <p className={buttonContainer}>
                <button className={button} type="submit">
                    <FontAwesomeIcon icon={faPaperPlane} className={icon}/>
                    Send
                </button>
            </p>
        </form>
    </>
)

export default ContactPage
