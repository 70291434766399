// extracted by mini-css-extract-plugin
export var backButtonContainer = "contact-module--backButtonContainer--c1c72";
export var backgroundColor = "#141821";
export var borderRadius = ".15rem";
export var button = "contact-module--button--dc770";
export var buttonContainer = "contact-module--buttonContainer--f76b8";
export var form = "contact-module--form--e0e59";
export var icon = "contact-module--icon--a40dc";
export var label = "contact-module--label--7cf3c";
export var mail = "contact-module--mail--f10b4";
export var mailContainer = "contact-module--mailContainer--110d0";
export var maxModalWidth = "48em";
export var message = "contact-module--message--d5e26";
export var messageContainer = "contact-module--messageContainer--fa60a";
export var modalBackgroundColor = "rgba(20,24,33,.75)";
export var name = "contact-module--name--210f2";
export var nameContainer = "contact-module--nameContainer--63007";
export var sender = "contact-module--sender--c1ddb";
export var twitterMessage = "contact-module--twitterMessage--5c292";
export var wpformsContainerFull = "contact-module--wpforms-container-full--59b9f";
export var wpformsForm = "contact-module--wpforms-form--c9450";
export var wpformsPageButton = "contact-module--wpforms-page-button--810fa";